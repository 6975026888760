<template>
	<div class="row">
		<div class="form-group col-md-3">
			<label for="filter_text">Buscar</label>
			<div class="input-group">
				<input v-model="text" @keypress.enter="mtd_filter" class="form-control form-control-sm" id="filter_text" type="text" />
				<button style="border-bottom-left-radius: 0; border-top-left-radius: 0" @click="mtd_filter" class="btn btn-primary btn-xs">Filtrar</button>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['prop-execute', 'page'],
	data() {
		return {
			text: '',
		};
	},
	created() {
		this.mtd_executeFilter('new');
	},
	computed: {
		...mapGetters(['url_api']),
	},
	watch: {
		propExecute: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
		page: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
	},
	methods: {
		...mapActions(['get', 'post', 'get_sin_bus', 'execute_commit']),
		mtd_filter: function () {
			this.mtd_executeFilter('new');
			this.$emit('emit-init-pagination');
		},
		mtd_executeFilter: function (type = null) {
			let pagination = null;
			type == 'new' ? (pagination = 1) : (pagination = this.page);

			const params = {
				url: `${this.url_api}/benefit_types?&page=${pagination}&text=${this.text}`,
				mutation: 'BENEFIT_TYPES_INDEX__SET_ALL',
			};
			this.get(params);
		},
	},
};
</script>
