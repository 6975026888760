<template>
	<div class="main-body" style="padding: 0">
		<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel" @action-create="mtd_showCreateResourceModal"></page-header>
		<div class="inner-main-body">
			<page-body
				:propHeader="dataTable.header"
				:propBody="dataTable.body"
				:propModel="dataModel"
				@action-sonwindow-close="mtd_sonWindowClose"
				@action-next-pagination="mtd_nextPagination"
				@action-edit="mtd_showEditResourceModal">
				<template slot="content-filter">
					<component :is="filter_component" @emit-init-pagination="mtd_initPagination" :prop-execute="executeFilter" :page="dataTable.header.page"></component>
				</template>
				<template slot="content-sonwindow">
					<component :is="dataTable.body.sonWindow.component" :propRow="dataTable.body.sonWindow.row" @action-sonwindow-close="mtd_sonWindowClose" @success="mtd_pageSuccess" />
				</template>
			</page-body>
		</div>
		<!--Form: create and update-->
		<modal :id="dataModal.config.id" :propModel="dataModel" :propConfig="dataModal.config" @action-close="mtd_closeModal1()">
			<component
				:is="dataModal.slot.component"
				slot="content-modal"
				:propInfo="dataModal.slot"
				@onload="mtd_componentOnload"
				@success="mtd_componentSuccess"
				@error="mtd_componentError"
				@close="mtd_closeModal1"></component>
		</modal>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
/** Components */
import MainForm from './components/Form';
import MainFilter from './components/Filter';
export default {
	components: {
		MainForm,
		MainFilter,
	},
	data() {
		return {
			filter_component: 'main-filter',
			executeFilter: 0,
			dataModel: {
				alias: 'Bonificaciones',
				icon: 'ri-tools-fill',
				getter: 'benefit_types_index',
				prefixPermission: 'benefit_types',
			},
			page: {
				header: {
					menu: ['Bonificaciones', 'Mantenimientos'],
				},
				buttons: {
					create: true,
					import: false,
					export: false,
				},
			},
			dataTable: {
				header: {
					title: 'Bonificaciones',
					filter: false,
					page: 1,
					wordsearch: '',
				},
				body: {
					cols: [
						{
							name: 'name',
							alias: 'Nombre',
							width: '',
						},
					],
					buttons: {
						edit: true,
						remove: false,
						width: '',
					},
					myButtons: [],
					sonWindow: {
						component: null,
						row: null,
					},
				},
				sonWindow: {
					component: null,
					row: null,
				},
			},
			dataModal: {
				slot: {
					component: null,
					data: {
						row: null,
						buttonSubmit: null,
						method: null,
					},
				},
				config: {
					title: null,
					id: 'modal__benefit_types',
					idHash: '#modal__benefit_types',
					size: null,
					scroll: false,
					position: '',
				},
			},
		};
	},
	computed: {
		...mapGetters(['url_api']),
	},
	methods: {
		...mapActions(['get', 'post', 'destroy', 'execute_commit', 'update']),
		mtd_pageSuccess: function () {
			//
		},
		mtd_componentSuccess: function ({ component, res, action }) {
			switch (component) {
				case 'main-form':
					if (action == 'store') {
						this.dataTable.header.page = 0;
						this.executeFilter++;
					}
					if (action == 'update') {
						this.execute_commit({ mutation: 'BENEFIT_TYPES_INDEX__UPDATE_RESOURCE', payload: { benefit_type: res.benefit_type } });
					}
					this.mtd_closeModal1();
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		mtd_showCreateResourceModal: function () {
			this.dataModal.slot.component = 'main-form';
			this.dataModal.slot.data = {
				row: null,
				buttonSubmit: 'Registrar bonificación',
				method: 'create',
			};
			this.dataModal.config.scroll = true;
			this.dataModal.config.size = 'modal-md';
			this.dataModal.config.title = "<span class = 'fa fa-plus'></span> Nueva bonificación";
		},
		mtd_showEditResourceModal: function (row) {
			this.dataModal.slot.component = 'main-form';
			this.dataModal.slot.data = {
				row: row,
				buttonSubmit: 'Actualizar bonificación',
				method: 'edit',
			};
			this.dataModal.config.scroll = true;
			this.dataModal.config.size = 'modal-md';
			this.dataModal.config.title = "<i class='far fa-edit'></i> Editando bonificación";
		},
		mtd_changeState: function (benefit_type) {
			this.update({
				url: `${this.url_api}/benefit_types/${benefit_type.id}/toggle_state`,
			})
				.then(res => {
					this.execute_commit({ mutation: 'BENEFIT_TYPES_INDEX__REMOVE_RESOURCE', payload: { benefit_type: res.benefit_type } });
					window.message('success', `Estado cambiado a ${benefit_type.state ? 'Inactivo' : 'Activo'}`, 'Bonificaciones');
				})
				.catch(() => {
					//
				});
		},
		mtd_componentOnload: function () {
			// eslint-disable-next-line no-undef
			$(this.dataModal.config.idHash).modal('show');
		},
		mtd_componentError: function () {
			//
		},
		mtd_closeModal1: function () {
			window.$(this.dataModal.config.idHash).modal('hide');
			this.dataModal.slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		mtd_sonWindowClose: function () {
			this.dataTable.body.sonWindow = {
				component: null,
				row: null,
			};
		},
		mtd_nextPagination: function (page) {
			this.dataTable.header.page = page;
		},
		mtd_initPagination: function () {
			this.dataTable.header.page = 0;
		},
	},
	beforeDestroy() {
		this.execute_commit({ mutation: 'BENEFIT_TYPES_INDEX__CLEAR_ALL' });
	},
};
</script>
